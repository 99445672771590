import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const blogsData: any[] = [
  {
    ImageComponent: (
      <StaticImage
        src="../../images/blogs/blog_cover_web2020-bibr.jpg"
        alt="I Started with Sweeping the Floors, Now I Lead a Platoon of Cleaners"
        style={{ height: `100%`, width: `100%` }}
        imgStyle={{
          objectFit: `cover`,
          height: `100%`,
          width: `100%`,
          objectPosition: `center center`,
        }}
      />
    ),
    link: `https://www.ackee.cz/blog/en/interview-with-jakub-baierl/`,
    name: `Interview With Our Team Dealer`,
  },
  {
    ImageComponent: (
      <StaticImage
        src="../../images/blogs/blog_cover_frontend_stack.png"
        alt="Our Web Frontend Tech-Stack"
        style={{ height: `100%`, width: `100%` }}
        imgStyle={{
          objectFit: `cover`,
          height: `100%`,
          width: `100%`,
          objectPosition: `center center`,
        }}
      />
    ),
    link: `https://www.ackee.cz/blog/en/our-web-frontend-technological-stack/`,
    name: `Our Web Frontend Tech-Stack`,
  },
  {
    ImageComponent: (
      <StaticImage
        src="../../images/blogs/blog_cover_html5.png"
        alt="HTML5 How You Might Not Know It"
        style={{ height: `100%`, width: `100%` }}
        imgStyle={{
          objectFit: `cover`,
          height: `100%`,
          width: `100%`,
          objectPosition: `center center`,
        }}
      />
    ),
    link: `https://www.ackee.cz/blog/en/html5-how-you-not-know-it/`,
    name: `HTML5 How You Might Not Know It`,
  },
  {
    ImageComponent: (
      <StaticImage
        src="../../images/blogs/blog_cover_web2020-scroll.jpg"
        alt="Scroll to element with React and Vanilla JavaScript"
        style={{ height: `100%`, width: `100%` }}
        imgStyle={{
          objectFit: `cover`,
          objectPosition: `center center`,
          height: `100%`,
          width: `100%`,
        }}
      />
    ),
    link: `https://www.ackee.cz/blog/en/scroll-to-element-with-react-and-vanilla-javascript/`,
    name: `Smooth Scroll – React & Vanilla JS`,
  },
  {
    ImageComponent: (
      <StaticImage
        src="../../images/blogs/blog_cover_google_captcha.png"
        alt="Who Will Solve Google reCaptcha?"
        style={{ height: `100%`, width: `100%` }}
        imgStyle={{
          objectFit: `cover`,
          height: `100%`,
          width: `100%`,
          objectPosition: `center center`,
        }}
      />
    ),
    link: `https://www.ackee.cz/blog/en/how-to-solve-google-recaptcha/`,
    name: `Who Will Solve Google reCaptcha?`,
  },
];

export default blogsData;
