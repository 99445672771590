import React from 'react';

import * as felaRules from './Footer.styles';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';
import Section from '../section';

import footerLinks from './footerLinks';
import ArrowIcon from '../ArrowIcon';
import SimpleLink from '../SimpleLink';

export const Footer: React.FC = () => {
  const { styles, rules } = useFelaEnhanced(felaRules);

  return (
    <Section extend={{ inner: rules.extendSectionInner }}>
      {footerLinks.map((item, index) => (
        <SimpleLink
          href={item.link}
          target="_blank"
          extend={{ link: rules.link }}
          key={index}
          rel="noreferrer"
        >
          <div className={styles.box}>
            <ArrowIcon fill="#fff" width={16} height={16} />
          </div>
          {item.name}
        </SimpleLink>
      ))}
    </Section>
  );
};

export default Footer;
