import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

export const extendSectionInner: TRuleWithTheme = ({ theme }) => ({
  maxWidth: `70rem`,
  color: theme.colors.white,

  display: `flex`,
  flexWrap: `wrap`,
});

const colSpacing = 16;

export const link: TRuleWithTheme = ({ theme }) => ({
  margin: colSpacing,

  display: `flex`,
  alignItems: `center`,

  phoneLarge: {
    flex: `1 0 calc(50% - ${colSpacing * 2}px)`,
    maxWidth: `calc(50% - ${colSpacing * 2}px)`,
  },

  tabletLarge: {
    flex: `1 0 calc(33% - ${colSpacing * 2}px)`,
    maxWidth: `calc(33% - ${colSpacing * 2}px)`,
  },
  textDecoration: `none`,
  color: theme.colors.white,
});

export const box: TRuleWithTheme = ({ theme }) => ({
  backgroundColor: theme.colors.blue,
  height: `2.5rem`,
  width: `2.5rem`,
  display: `inline-flex`,
  alignItems: `center`,
  justifyContent: `center`,
  border: `1px solid ${theme.colors.white}`,
  marginRight: `2.5rem`,
});
