import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={54}
      viewBox="0 0 48 54"
      {...props}
    >
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        filter="url(#prefix__a)"
        transform="translate(-6 -3)"
      >
        <path
          fill="#000"
          d="M30 3L6.75 16.5v27L30 57l23.25-13.5v-27L30 3zM9 42.205v-24.41L30 5.602l21 12.193v24.41L30 54.398 9 42.205z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
