import React from 'react';

import * as felaRules from './Projects.styles';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';
import Section from '../section';
import LargeTitle from '../LargeTitle';

import projectsData from './projectsData';
import ProjectCard from '../ProjectCard';

import StackedButton from '../StackedButton';
import SimpleLink from '../SimpleLink';

export const Projects: React.FC = () => {
  const { styles, rules } = useFelaEnhanced(felaRules);

  return (
    <Section extend={{ wrapper: rules.extendSection }}>
      <LargeTitle htmlType="h2" extend={{ container: rules.extendTitle }}>
        Our favourite web projects
      </LargeTitle>

      <div className={styles.grid}>
        {projectsData.map((project, index) => (
          <ProjectCard key={index} {...project} />
        ))}
      </div>

      <div className={styles.readMoreWrapper}>
        <StackedButton
          RenderComponent="a"
          href="https://www.ackee.cz/en/references/"
          target="_blank"
        >
          Load more
        </StackedButton>
      </div>
    </Section>
  );
};

export default Projects;
