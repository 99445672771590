import React from 'react';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';

import * as felaRules from './Team.styles';
import LargeTitle from '../LargeTitle';

import teamData from './teamData';
import TeamFlippableCard from '../TeamFlippableCard';
import Section from '../section';
import { TeamFrontCard } from '../TeamCard';
import { StaticImage } from 'gatsby-plugin-image';

const Team: React.FC = () => {
  const { styles, rules } = useFelaEnhanced(felaRules);

  return (
    <Section id="team">
      <LargeTitle htmlType="h2" extend={{ container: rules.extendTitle }}>
        Meet our frontend team
      </LargeTitle>

      <div className={styles.grid}>
        {teamData.map((person, index) => (
          <div className={styles.col} key={index}>
            <TeamFlippableCard {...person} />
          </div>
        ))}
      </div>
    </Section>
  );
};

export default Team;
