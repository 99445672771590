import React from 'react';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';

import * as felaRules from './Header.styles';
import Logo from '../Logo';
import Paragraph from '../Paragraph';
import Marquee from 'react-fast-marquee';
import LargeTitle from '../LargeTitle';
import OvalButton from '../OvalButton';

const Header: React.FC = () => {
  const { styles, rules } = useFelaEnhanced(felaRules);

  return (
    <header className={styles.header}>
      <div className={styles.topWrapper}>
        <Logo />

        <a href="#join-us" className={styles.joinUs} rel="noreferrer">
          Join Us
        </a>
      </div>

      <div className={styles.titleWrapper}>
        <Marquee
          direction="left"
          speed={100}
          gradient={false}
          gradientWidth={0}
          play
        >
          <LargeTitle extend={{ container: rules.extendTitle }} responsive>
            We are React Boys
          </LargeTitle>
        </Marquee>
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.contentTextWrapper}>
          <Paragraph extend={{ container: rules.extendParagraph }}>
            We are a team of frontend professionals from Ackee & we love React.
            Are you a real enthusiast of all you can code? Do you want to code
            websites and program apps in JavaScript? Whether you are a react
            boy, girl or other human being, you are more than welcome! Discover
            who we are and what we do.
          </Paragraph>
        </div>

        <a href="#team" className={styles.readMore}>
          <OvalButton htmlType="span">Get to know us</OvalButton>
        </a>
      </div>
    </header>
  );
};

export default Header;
