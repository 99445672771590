import React from 'react';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';

import * as felaRules from './SimpleLink.styles';
import { RulesExtend } from '../../gatsby-theme-fela/styles/theme';

interface SimpleLinkProps {
  extend?: RulesExtend<typeof felaRules>;
  opacity?: number;
  LinkComponent?: React.FunctionComponent<any> | 'a';
  [key: string]: any;
}

const SimpleLink: React.FC<SimpleLinkProps> = ({
  children,
  extend,
  opacity,
  LinkComponent = `a`,
  ...props
}) => {
  const { styles } = useFelaEnhanced(felaRules, { extend, opacity });

  return React.createElement(
    LinkComponent,
    {
      ...props,
      className: [props.className, styles.link].filter(Boolean).join(` `),
    },
    children,
  );
};

export default SimpleLink;
