import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

export const link: TRuleWithTheme<{ opacity?: number }> = ({
  theme,
  opacity = 0.8,
}) => ({
  color: theme.colors.white,
  transition: `0.3s opacity ease`,
  ':active': {
    color: theme.colors.white,
  },
  ':hover': {
    color: theme.colors.white,
    textDecoration: `none`,
    opacity,
  },
});
