import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={45}
      viewBox="0 0 48 45"
      {...props}
    >
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        fill="#000"
        fillRule="evenodd"
        filter="url(#prefix__a)"
        transform="translate(-6 -8)"
      >
        <path d="M38.564 40.067c.188.03.376.058.573.058a3.75 3.75 0 10-3.75-3.75c0 1.062.444 2.016 1.154 2.698-1.184 2.002-5.421 8.29-13.055 10.469-1.645.47-3.219.708-4.675.708-4.121 0-7.373-1.93-9.407-5.58-.449-.805-4.134-8.05 4.726-14.465l-1.32-1.823C2.376 35.938 6.891 44.783 7.44 45.766 9.892 50.172 13.824 52.5 18.81 52.5c1.666 0 3.447-.267 5.293-.794 8.551-2.442 13.236-9.543 14.46-11.639" />
        <path d="M48.699 29.396c-6.366-6.414-15-6.993-17.294-7.028a3.738 3.738 0 00-3.518-2.493 3.75 3.75 0 100 7.5 3.739 3.739 0 003.597-2.75c2.152.062 9.983.679 15.618 6.355 4.643 4.678 5.43 9.68 2.28 14.464-.297.452-3.061 4.412-8.542 4.413-2.057 0-4.284-.555-6.622-1.65l-.955 2.038c2.64 1.235 5.188 1.862 7.577 1.862 6.522 0 9.825-4.52 10.422-5.425 3.699-5.618 2.765-11.919-2.563-17.286" />
        <path d="M18.887 39.375a3.75 3.75 0 103.75-3.75c-.34 0-.664.06-.977.145-1.362-2.322-4.432-8.59-2.817-15.617 1.451-6.315 5.31-9.653 11.162-9.653.942 0 9.245.305 10.714 10.888l2.228-.31C41.217 8.609 31.147 8.25 30.004 8.25c-6.922 0-11.664 4.048-13.353 11.4-1.814 7.886 1.642 14.846 3.115 17.339a3.714 3.714 0 00-.88 2.386" />
      </g>
    </svg>
  );
}

export default SvgComponent;
