import React from 'react';
import useFelaEnhanced from '../../../hooks/useFelaEnhanced';

import * as felaRules from './Layout.styles';
import ArrowIcon from '../../ArrowIcon';
import SimpleLink from '../../SimpleLink';
import { useIsSafari } from '../../../hooks/useIsSafari';

export interface CardLayoutProps {
  name: string;
  surname: string;
  link: string;
  extend?: any;
}

const CardLayout: React.FC<CardLayoutProps> = ({
  name,
  surname,
  link,
  children,
  extend,
}) => {
  const isSafari = useIsSafari();
  const { styles } = useFelaEnhanced(felaRules, { extend, isSafari });

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperBg} />
      <div className={styles.content}>
        <div className={styles.header}>
          <h3 className={styles.headerLeft}>
            <span>{name}</span>
            <span>{surname}</span>
          </h3>
          {link && (
            <div className={styles.headerRight}>
              <SimpleLink
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                opacity={isSafari ? 1 : 0.6}
              >
                <ArrowIcon />
              </SimpleLink>
            </div>
          )}
        </div>

        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
};

export default CardLayout;
