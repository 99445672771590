import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

export const extendSection: TRuleWithTheme = ({ theme }) => ({
  backgroundColor: theme.colors.blue,
  color: theme.colors.white,
});

export const extendTitle: TRuleWithTheme = ({ theme }) => ({
  color: theme.colors.white,
  fontSize: `2.5rem`,
  letterSpacing: `1.82px`,
  textShadow: `2px 2px ${theme.colors.black}`,
});

export const sectionWrapper: TRuleWithTheme = ({ theme }) => ({
  marginTop: `3.5rem`,
  display: `flex`,
  flexWrap: `wrap`,
  flexDirection: `col`,
});

export const extendParagraph: TRuleWithTheme = () => ({
  maxWidth: `34rem`,
  width: `100%`,
  fontWeight: 100,
  letterSpacing: `0.91px`,

  order: 1,
  tablet: {
    order: 2,
  },
});

export const extendSectionInner: TRuleWithTheme = ({ theme }) => ({
  maxWidth: `58rem`,

  paddingLeft: `1rem`,
  paddingRight: `1rem`,
});

export const buttonWrapper: TRuleWithTheme = ({ theme }) => ({
  marginRight: `5rem`,
  display: `inline-flex`,
  textAlign: `center`,
  justifyContent: `center`,
  minWidth: `12rem`,

  order: 2,
  marginTop: `2rem`,
  tablet: {
    marginTop: 0,
    order: 1,
  },
});
