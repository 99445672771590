import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

export const wrapper: TRuleWithTheme = ({ theme }) => ({
  border: `2px solid ${theme.colors.black}`,
  backgroundColor: theme.colors.white,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,

  '&:hover': {
    '& [role="link"]': {
      opacity: 0.3,
    },
  },
});

export const body: TRuleWithTheme = ({ theme }) => ({
  width: `100%`,
  height: `100%`,
  minHeight: 300,
  padding: `2rem`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
});

export const footer: TRuleWithTheme = ({ theme }) => ({
  borderTop: `2px solid ${theme.colors.black}`,
  height: `3.125rem`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-between`,
  width: `100%`,
  paddingLeft: `1.25rem`,
  fontWeight: 100,
});

export const button: TRuleWithTheme = ({ theme }) => ({
  display: `flex`,
  justifyContent: `center`,
  backgroundColor: theme.colors.cyan,
  width: `3.125rem`,
  alignItems: `center`,
  padding: `0.625rem`,
  textAlign: `center`,
  height: `100%`,
  borderLeft: `2px solid ${theme.colors.black}`,
  textDecoration: `none`,
  transition: `0.3s opacity ease`,
});
