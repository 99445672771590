import React from 'react';

import * as felaRules from './Card.styles';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';
import ArrowIcon from '../ArrowIcon';
import { RulesExtend } from '../../gatsby-theme-fela/styles/theme';

export interface CardProps {
  footerText?: string;
  extend?: RulesExtend<typeof felaRules>;
}

const Card: React.FC<CardProps> = ({ children, footerText, extend }) => {
  const { styles } = useFelaEnhanced(felaRules, { extend });

  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>{children}</div>
      {footerText && (
        <div className={styles.footer}>
          <span>{footerText}</span>
          <span className={styles.button} role="link">
            <ArrowIcon height={18} width={18} />
          </span>
        </div>
      )}
    </div>
  );
};

export default Card;
