import Html5 from './Icons/Html5';
import React from './Icons/React';
import Redux from './Icons/Redux';
import Fela from './Icons/Fela';
import Gatsby from './Icons/Gatsby';
import Typescript from './Icons/Typescript';
import NodeJS from './Icons/NodeJS';
import GoogleCloud from './Icons/GoogleCloud';

const technologiesData = [
  {
    link: `https://cs.wikipedia.org/wiki/HTML5`,
    IconComponent: Html5,
  },
  {
    link: `https://reactjs.org/`,
    IconComponent: React,
  },
  {
    link: `https://redux.js.org/`,
    IconComponent: Redux,
  },
  {
    link: `https://fela.js.org/`,
    IconComponent: Fela,
  },
  {
    link: `https://www.gatsbyjs.com/`,
    IconComponent: Gatsby,
  },
  {
    link: `https://www.typescriptlang.org/`,
    IconComponent: Typescript,
  },
  {
    link: `https://nodejs.dev/`,
    IconComponent: NodeJS,
  },
  {
    link: `https://cloud.google.com/`,
    IconComponent: GoogleCloud,
  },
];

export default technologiesData;
