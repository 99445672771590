import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={47}
      height={55}
      viewBox="0 0 47 55"
      {...props}
    >
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        filter="url(#prefix__a)"
        transform="translate(-7 -3)"
      >
        <path
          fill="#000"
          d="M7.5 20.25v3.9l3.375 1.949 3.375-1.95v-3.9l-3.375-1.95L7.5 20.25zm2.25 2.601V21.55l1.125-.65 1.125.65v1.302l-1.125.65-1.125-.65zm9.346-8.25l-2.423 1.4-2.423-1.4v-2.803l2.423-1.4 2.423 1.4v2.803zm2.25-4.102L16.673 7.8 12 10.5v5.4l4.673 2.7 4.673-2.7v-5.4zM30.75 3L24 6.9v7.8l6.75 3.9 6.75-3.9V6.9L30.75 3zm-4.5 10.401V8.198l4.5-2.6 4.5 2.6v5.203l-4.5 2.6-4.5-2.6zm9.75 5.05v9.9l8.568 4.948 8.566-4.949v-9.9l-8.566-4.95L36 18.45zm2.25 8.6v-7.303l6.318-3.65 6.317 3.65v7.304l-6.317 3.65-6.317-3.65zm-20.128 8.362v14.591L30.75 57.3l12.629-7.295V35.413l-12.629-7.296-12.627 7.296zm2.25 13.293V36.712l10.377-5.996 10.379 5.996v11.994L30.749 54.7l-10.377-5.995z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
