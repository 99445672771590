import React from 'react';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';

import * as felaRules from './TeamFlippableCard.styles';
import { TeamBackCard, TeamFrontCard } from '../TeamCard';
import { TeamFrontCardProps } from '../TeamCard/FrontCard';
import { TeamBackCardProps } from '../TeamCard/BackCard';

const TeamFlippableCard: React.FC<
  Omit<TeamFrontCardProps & TeamBackCardProps, 'handleFlip'>
> = ({
  name,
  surname,
  link,
  sinceYear,
  specialization,
  motto,
  mottoTitle,
  ImageComponent,
  videoSrc,
}) => {
  const [flipped, setFlipped] = React.useState<boolean>(false);
  const handleClick = () => setFlipped((wasFlipped) => !wasFlipped);

  const { styles, rules } = useFelaEnhanced(felaRules, { flipped });

  return (
    <div className={styles.wrapper}>
      <TeamFrontCard
        name={name}
        surname={surname}
        link={link}
        sinceYear={sinceYear}
        specialization={specialization}
        handleFlip={handleClick}
        extend={{ wrapper: rules.frontWrapper }}
        ImageComponent={ImageComponent}
        videoSrc={videoSrc}
        mottoTitle={mottoTitle}
      />

      <TeamBackCard
        name={name}
        surname={surname}
        link={link}
        motto={motto}
        handleFlip={handleClick}
        extend={{ wrapper: rules.backWrapper, body: rules.backBody }}
      />
    </div>
  );
};

export default TeamFlippableCard;
