import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

const spaceGap = 8; // percent

export const container: TRuleWithTheme<{ baseColor?: string }> = ({
  theme,
  baseColor,
}) => ({
  border: 0,
  cursor: `pointer`,
  color: baseColor || theme.colors.blue,
  padding: `20px 32px`,
  fontSize: `16px`,
  display: `inline-block`,
  position: `relative`,
  textDecoration: `none`,
  margin: `0 1rem 2rem 0`,
  boxSizing: `border-box`,
  width: `auto`,
  overflow: `visible`,
  lineHeight: 1,
  whiteSpace: `normal`,
  '-webkit-appearance': `none`,
  fontWeight: 500,
  letterSpacing: `0.91px`,

  ':before': {
    content: `" "`,
    display: `block`,
    position: `absolute`,
    width: `100%`,
    height: `100%`,
    left: 0,
    top: 0,
    transform: `translate(${spaceGap}px, ${spaceGap}px)`,
    border: `2px solid ${baseColor || theme.colors.white}`,
    fontSize: 0,
    transition: `transform ${theme.transition}`,
  },

  ':after': {
    background: theme.colors.white,
    content: `" "`,
    display: `block`,
    position: `absolute`,
    width: `100%`,
    height: `100%`,
    top: 0,
    left: 0,
    border: `2px solid ${baseColor || theme.colors.white}`,
    fontSize: 0,
    transition: `transform ${theme.transition}`,
  },

  ':hover': {
    ':after': {
      transform: `translate(${spaceGap}px, ${spaceGap}px)`,
    },
    '>*': {
      transform: `translate(${spaceGap}px, 50%)`,
    },
  },
});

export const innerWrapper: TRuleWithTheme = ({ theme }) => ({
  position: `relative`,
  zIndex: 2,
  transition: `transform ${theme.transition}`,
  lineHeight: `auto`,
  display: `block`,
});
