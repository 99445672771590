import React from 'react';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';

import * as felaRules from './Technologies.styles';
import LargeTitle from '../LargeTitle';

import technologiesData from './technologiesData';
import Section from '../section';
import SimpleLink from '../SimpleLink';

const Technologies: React.FC = () => {
  const { styles, rules } = useFelaEnhanced(felaRules);

  return (
    <Section>
      <LargeTitle htmlType="h2" extend={{ container: rules.extendTitle }}>
        Technologies we use
      </LargeTitle>

      <div className={styles.grid}>
        {technologiesData.map(({ IconComponent, ...technology }, index) => (
          <div key={index} className={styles.col}>
            <SimpleLink
              href={technology.link}
              target="_blank"
              rel="noreferrer noopener nofollow"
              opacity={0.5}
            >
              <IconComponent />
            </SimpleLink>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default Technologies;
