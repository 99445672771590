import React from 'react';
import { PageProps } from 'gatsby';

import Header from '../components/Header';
import Team from '../components/Team';
import Projects from '../components/Projects';
import Technologies from '../components/Technologies';
import Blogs from '../components/Blogs';
import OpenSource from '../components/OpenSource';
import JoinUs from '../components/JoinUs';
import Footer from '../components/Footer';
import Seo from '../components/Seo';

const Home: React.FC<PageProps> = () => (
  <>
    <Seo />
    <main>
      <Header />
      <Team />
      <Projects />
      <Technologies />
      <OpenSource />
      <Blogs />
      <JoinUs />
      <Footer />
    </main>
  </>
);

export default Home;
