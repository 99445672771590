const footerLinks = [
  {
    name: `FE Cookbook`,
    link: `https://frontend-cookbook.ack.ee/`,
  },
  {
    name: `Wtf is Ackee`,
    link: `https://wtfisackee.dev/`,
  },
  {
    name: `Ackee`,
    link: `https://www.ackee.cz/en/`,
  },
  {
    name: `Facebook`,
    link: `https://www.facebook.com/AckeeCZ`,
  },
  {
    name: `LinkedIn`,
    link: `https://www.linkedin.com/company/ackee`,
  },
  {
    name: `Instagram`,
    link: `https://www.instagram.com/ackeecz/`,
  },
];

export default footerLinks;
