import React from 'react';

// Caching
let _isSafari = undefined;

export function useIsSafari() {
  const [isSafari, setIsSafari] = React.useState<boolean>(Boolean(_isSafari));

  React.useEffect(() => {
    if (_isSafari !== undefined) {
      setIsSafari(_isSafari);
      return;
    }

    _isSafari =
      // @ts-ignore
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === `[object SafariRemoteNotification]`;
      })(
        !window[`safari`] ||
          // @ts-ignore
          (typeof safari !== `undefined` && safari.pushNotification),
      );

    setIsSafari(_isSafari);
  }, []);

  return isSafari;
}
