import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

const spaceGap = 15; // percent

export const container: TRuleWithTheme = ({ theme }) => ({
  border: 0,
  borderRadius: `50%`,
  backgroundColor: theme.colors.blue,
  padding: `14px 28px`,
  fontSize: `16px`,
  color: theme.colors.white,
  display: `block`,
  transform: `rotate(-10deg)`,
  position: `relative`,
  textDecoration: `none`,
  margin: `0 1rem 2rem 0`,
  boxSizing: `border-box`,
  width: `auto`,
  overflow: `visible`,
  lineHeight: 1,
  whiteSpace: `normal`,
  '-webkit-appearance': `none`,

  ':before': {
    content: `" "`,
    borderRadius: `50%`,
    display: `block`,
    position: `absolute`,
    width: `100%`,
    height: `100%`,
    left: 0,
    top: 0,
    transform: `translateY(${spaceGap}%)`,
    border: `2px solid ${theme.colors.white}`,
    fontSize: 0,
    transition: `transform ${theme.transition}`,
  },

  ':after': {
    backgroundColor: theme.colors.blue,
    content: `" "`,
    borderRadius: `50%`,
    display: `block`,
    position: `absolute`,
    width: `100%`,
    height: `100%`,
    left: 0,
    top: 0,
    border: `2px solid ${theme.colors.white}`,
    fontSize: 0,
    transition: `transform ${theme.transition}`,
  },

  ':hover': {
    ':after': {
      transform: `translateY(${spaceGap}%)`,
    },
    '>*': {
      transform: `translateY(50%)`,
    },
  },
});

export const innerWrapper: TRuleWithTheme = ({ theme }) => ({
  position: `relative`,
  zIndex: 2,
  transition: `transform ${theme.transition}`,
  lineHeight: `auto`,
  display: `block`,
});
