import { TRuleWithTheme } from '../../../gatsby-theme-fela/styles/theme';

export const wrapper: TRuleWithTheme = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  color: theme.colors.black,
  border: `2px solid ${theme.colors.blue}`,
  paddingTop: `7.5rem 2rem`,
  maxWidth: `100%`,
  position: `relative`,
  overflow: `visible`,
  height: `100%`,
  width: `100%`,
});

export const wrapperBg: TRuleWithTheme = ({ theme }) => ({
  backgroundColor: theme.colors.blue,
  position: `absolute`,
  height: `100%`,
  width: `100%`,
  top: `0.5rem`,
  left: `0.5rem`,
  zIndex: 1,
});

export const content: TRuleWithTheme = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  zIndex: 2,
  position: `relative`,
  display: `flex`,
  flexDirection: `column`,
  height: `100%`,
});

export const header: TRuleWithTheme = ({ theme }) => ({
  borderBottom: `2px solid ${theme.colors.blue}`,
  height: `4.5rem`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-between`,
  flexShrink: 0,
});

export const headerLeft: TRuleWithTheme = ({ theme }) => ({
  display: `flex`,
  flexDirection: `column`,
  fontSize: `1rem`,
  letterSpacing: `0.3px`,
  fontWeight: 500,
  paddingLeft: `1.25rem`,
  paddingRight: `1.25rem`,
});

export const headerRight: TRuleWithTheme = ({ theme }) => ({
  borderLeft: `2px solid ${theme.colors.blue}`,
  display: `flex`,
  alignItems: `center`,
  justifyItems: `center`,
  width: `4.5rem`,
  height: `100%`,

  '>*': {
    display: `flex`,
    width: `100%`,
    height: `100%`,
    justifyContent: `center`,
    alignItems: `center`,
  },
});

export const body: TRuleWithTheme = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  height: `100%`,
});
