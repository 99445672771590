import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

export const container: TRuleWithTheme<{ responsive?: boolean }> = ({
  theme,
  responsive,
}) => ({
  color: theme.colors.white,

  fontSize: `2rem`,
  textShadow: `4px 4px ${theme.colors.black}`,

  ...(responsive && {
    tablet: {
      fontSize: `5.5rem`,
      textShadow: `6px 6px ${theme.colors.black}`,
    },

    '@media (min-width: 1024px) and (min-height: 800px)': {
      fontSize: `8.25rem`,
      textShadow: `8px 8px ${theme.colors.black}`,
    },
  }),

  fontWeight: 500,
  letterSpacing: `6.18px`,
  textTransform: `uppercase`,
  margin: `0`,
  padding: 0,
  lineHeight: `auto`,
  textAlign: `center`,
});
