import { combineRules } from 'fela';
import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

import { link as simpleLinkStyles } from '../SimpleLink/SimpleLink.styles';

export const picture: TRuleWithTheme = ({ theme }) => ({
  display: `flex`,
  justifyItems: `center`,
  alignItems: `center`,
  margin: `3rem auto`,
  backgroundColor: theme.colors.black,
  borderRadius: `50%`,
  height: `15rem`,
  width: `15rem`,
  flexShrink: 0,
  overflow: `hidden`,
});

export const pictureCircle: TRuleWithTheme = ({ theme }) => ({
  margin: `auto`,
  position: `relative`,
  borderRadius: `50%`,
});

export const bodyRow: TRuleWithTheme = ({ theme }) => ({
  borderTop: `2px solid ${theme.colors.blue}`,
  display: `flex`,
  width: `100%`,
  padding: `0.5rem 1.25rem`,
  alignItems: `center`,
  minHeight: `3.125rem`,
  color: theme.colors.black,
});

export const bodyMottoRow: TRuleWithTheme = combineRules(
  bodyRow,
  ({ theme }) => ({
    padding: `0 0 0 1.25rem`,
    display: `flex`,
    justifyContent: `space-between`,
  }),
);

export const flipButton: TRuleWithTheme<{ isBack: boolean }> = ({
  theme,
  isBack = false,
}) => ({
  backgroundColor: theme.colors.cyan,
  width: `4.5rem`,
  height: `100%`,
  alignItems: `center`,
  padding: `0.625rem`,
  transition: `0.3s opacity ease`,
  position: `relative`,
  zIndex: 2,

  ':hover': {
    opacity: isBack ? 0.7 : 0.3,
  },
});

export const backBody: TRuleWithTheme = ({ theme }) => ({
  backgroundColor: theme.colors.blue,
  height: `100%`,
  width: `100%`,
  minHeight: `10rem`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `space-between`,
  padding: `0.625rem 0.625rem 0`,
});

// @ts-ignore
export const paragraphExtend: TRuleWithTheme = ({ theme }) => ({
  fontSize: `1rem`,
  letterSpacing: `0.73px`,
  lineHeight: `1.5rem`,
  padding: `1.5rem 2rem`,

  tabletLarge: {
    fontSize: `1.25rem`,
    lineHeight: `1.75rem`,
  },

  // @ts-expect-error
  '& a': simpleLinkStyles({ theme }),
});

export const video: TRuleWithTheme = ({ theme }) => ({
  width: `100%`,
  height: `100%`,
  objectFit: `contain`,
  objectPosition: `center`,
  borderRadius: `50%`,
});
