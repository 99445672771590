import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={51}
      height={51}
      viewBox="0 0 51 51"
      {...props}
    >
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        filter="url(#prefix__a)"
        transform="translate(-5 -5)"
      >
        <path
          fill="#000"
          d="M55.807 28.875H41.954v2.25h11.613c-.298 9.013-5.942 17.093-14.302 20.47l-29.78-29.78C12.955 13.223 21.392 7.5 30.705 7.5c6.11 0 11.855 2.38 16.175 6.7l1.59-1.59c-4.744-4.747-11.054-7.36-17.766-7.36-10.49 0-19.97 6.612-23.589 16.454l-.248.676L38.7 54.212l.676-.248c9.842-3.62 16.454-13.1 16.454-23.589l-.022-1.5zm-50.557.221c0 14.765 11.97 26.735 26.735 26.735.545 0 1.085-.016 1.622-.048h.001L5.3 27.475c-.032.537-.05 1.077-.05 1.621"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
