import React from 'react';
import { TeamFrontCardProps } from '../TeamCard/FrontCard';
import { TeamBackCardProps } from '../TeamCard/BackCard';
import { StaticImage } from 'gatsby-plugin-image';

const teamData: Omit<TeamFrontCardProps & TeamBackCardProps, 'handleFlip'>[] = [
  {
    name: `Jakub`,
    surname: `Baierl`,
    link: `https://www.instagram.com/borecekbaji/`,
    sinceYear: 2013,
    specialization: `Team Mascot`,
    motto: `Declare variables, not war`,
    videoSrc: require(`../../video/people/bibr.mp4`).default,
    mottoTitle: `Find out more`,
  },
  {
    name: `Marek`,
    surname: `Janča`,
    link: `https://twitter.com/marekjanca`,
    sinceYear: 2015,
    specialization: `Coffeemaster Pixelperfect`,
    motto: `Keep it simple and don’t make a bad design ruin your day.`,
    videoSrc: require(`../../video/people/manca.mp4`).default,
    mottoTitle: `Find out more`,
  },
  {
    name: `Lukáš`,
    surname: `Horák`,
    link: `https://github.com/horaklukas`,
    sinceYear: 2017,
    specialization: `Testing Guru & Stack Leader`,
    motto: `May the Open source be with you Luke`,
    videoSrc: require(`../../video/people/luky.mp4`).default,
    mottoTitle: `Find out more`,
  },
  {
    name: `Jiří`,
    surname: `Čermák`,
    link: `https://github.com/cermakjiri`,
    sinceYear: 2018,
    specialization: `Abstractions Designer`,
    motto: `Aim to perfect but preserve the straightforwardness. My goal is to design abstractions that are easily apprehensible not only by me but other developers as well.`,
    videoSrc: require(`../../video/people/cermi.mp4`).default,
    mottoTitle: `Find out more`,
  },
  {
    name: `Jiří`,
    surname: `Zdvomka`,
    link: `https://github.com/jstorm31`,
    sinceYear: 2017,
    specialization: `Frontend Laborer`,
    motto: `Even though I believe IT ruins my back, I even code in my free time. Besides Ackee, I am the creator of the web app <a href="https://swapper.cz/" target="_blank">Swapper</a>, the first peer buy/sell e-tickets for events app.`,
    videoSrc: require(`../../video/people/jirka.mp4`).default,
    mottoTitle: `Find out more`,
  },
  {
    name: `Filip`,
    surname: `Kubík`,
    link: ``,
    sinceYear: 2019,
    specialization: `Margin & Padding Specialist`,
    motto: `I am a part of the frontend team, despite the fact that I studied graphic design. Naturally, I have always been close to animations and visuals and I take coding as an art form of the digital era.`,
    videoSrc: require(`../../video/people/fila.mp4`).default,
    mottoTitle: `Find out more`,
  },
  {
    name: `Martin`,
    surname: `Foldyna`,
    link: `https://github.com/martinfoldyna`,
    sinceYear: 2021,
    specialization: `Turns Design Into Code`,
    motto: `What I enjoy the most about coding is the fact that you can do whatever you can imagine. Whether it is the next Facebook or just your side project.`,
    videoSrc: require(`../../video/people/martin.mp4`).default,
    mottoTitle: `Find out more`,
  },
  {
    name: `Adam`,
    surname: `Tretera`,
    link: `https://github.com/adamtretera`,
    sinceYear: 2020,
    specialization: `Stack enthusiast`,
    motto: `The future is still so much bigger than the past. 
    Let's make the Web. Faster.`,
    videoSrc: require(`../../video/people/adam.mp4`).default,
    mottoTitle: `Find out more`,
  },
  {
    name: `Ivan`,
    surname: `Harašta`,
    link: `https://github.com/harastaivan`,
    sinceYear: 2022,
    specialization: `Code by day, rev by night`,
    motto: `Why am I as a BMW driver developing so slow? Because I keep stopping to admire my own code.`,
    videoSrc: require(`../../video/people/ivan.mp4`).default,
    mottoTitle: `Find out more`,
  },
  {
    name: `Your name`,
    surname: `Your surname`,
    link: null,
    sinceYear: new Date().getUTCFullYear().toString(),
    specialization: `Your specialization`,
    motto: `What are you passionate about?<br><br>What projects do you love to work on?<br><br>What apps are you proud of?`,
    ImageComponent: (
      <StaticImage
        aspectRatio={1}
        src="../../images/people/you.png"
        alt="You"
      />
    ),
    mottoTitle: `Tell us more`,
  },
];

export default teamData;
