import React from 'react';

import * as felaRules from './JoinUs.styles';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';
import Section from '../section';
import Paragraph from '../Paragraph';
import LargeTitle from '../LargeTitle';
import StackedButton from '../StackedButton';
import { StaticImage } from 'gatsby-plugin-image';
import SimpleLink from '../SimpleLink';

export const JoinUs: React.FC = () => {
  const { styles, rules } = useFelaEnhanced(felaRules);

  return (
    <Section
      extend={{ wrapper: rules.extendSection, inner: rules.extendSectionInner }}
      id="join-us"
    >
      <LargeTitle htmlType="h2" extend={{ container: rules.extendTitle }}>
        Join us
      </LargeTitle>

      <div className={styles.inner}>
        <div className={styles.left}>
          <Paragraph extend={{ container: rules.extendParagraph }}>
            Take a chance and{` `}
            <SimpleLink
              href="https://www.ackee.cz/en/career/frontend-developer"
              target="_blank"
            >
              become a part of the best team ever
            </SimpleLink>
            {` `}
            backe(n)d up by one of the{` `}
            <SimpleLink
              href="https://www.ackee.cz/blog/en/clutch-awards-for-ackee/"
              target="_blank"
            >
              top software houses in the world
            </SimpleLink>
            {` `}– where we keep up to date tech stack, take care of tech and
            personal development, share know-how and give regular feedback.
            <br />
            <br />
            Do you want to see what our tasks look like?
          </Paragraph>

          <StackedButton
            RenderComponent="a"
            href="https://github.com/AckeeCZ/cookbook-web-task/"
            target="_blank"
          >
            React Now
          </StackedButton>
        </div>
        <div className={styles.right}>
          <StaticImage
            src="../../images/unicorn.png"
            alt="Unicorn"
            className={styles.unicorn}
            quality={100}
          />
        </div>
      </div>
    </Section>
  );
};

export default JoinUs;
