import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

const colSpacing = 16;

export const grid: TRuleWithTheme = ({ theme }) => ({
  marginTop: `3.75rem`,
  desktop: {
    display: `flex`,
    flexWrap: `wrap`,
  },
});

export const col: TRuleWithTheme = ({ theme }) => ({
  margin: colSpacing,
  textDecoration: `none`,

  desktop: {
    flex: `1 0 calc(33% - ${colSpacing * 2}px)`,
    maxWidth: `calc(33% - ${colSpacing * 2}px)`,
  },
  desktopLarge: {
    flex: `1 0 calc(25% - ${colSpacing * 2}px)`,
    maxWidth: `calc(25% - ${colSpacing * 2}px)`,
  },
});

export const extendTitle: TRuleWithTheme = ({ theme }) => ({
  fontSize: `2.5rem`,
  letterSpacing: `1.82px`,
  textShadow: `2px 2px ${theme.colors.blue}`,
});
