import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

export const extendSection: TRuleWithTheme = ({ theme }) => ({
  backgroundColor: theme.colors.blue,
  color: theme.colors.white,
});

export const extendSectionInner: TRuleWithTheme = ({ theme }) => ({
  maxWidth: `54rem`,

  desktopFullHD: {
    maxWidth: `71rem`,
  },
});

export const extendTitle: TRuleWithTheme = ({ theme }) => ({
  color: theme.colors.white,

  fontSize: `2.5rem`,
  marginBottom: `2rem`,

  tablet: {
    fontSize: `8.5rem`,
    marginBottom: 0,
  },

  tabletLarge: {
    fontSize: `8.5rem`,
    marginBottom: 0,
  },

  letterSpacing: `6.18px`,
  textAlign: `left`,
  position: `relative`,
  zIndex: 2,
});

export const extendParagraph: TRuleWithTheme = () => ({
  paddingBottom: `3rem`,
  fontSize: `1.25rem`,
  lineHeight: `1.65rem`,

  tabletLarge: {
    paddingRight: `10rem`,
    fontSize: `1.25rem`,
    lineHeight: `1.65rem`,
  },
});

export const inner: TRuleWithTheme = ({ theme }) => ({
  display: `flex`,
  justifyContent: `space-between`,
  flexDirection: `column`,
  tabletLarge: {
    flexDirection: `row`,
    marginTop: `-5.5rem`,
  },
});

export const left: TRuleWithTheme = ({ theme }) => ({
  alignSelf: `flex-end`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `space-between`,
  alignItems: `flex-start`,
  height: `100%`,
  order: 2,
  paddingTop: `1.5rem`,

  tabletLarge: {
    paddingTop: `5.5rem`,
    order: 1,
    maxWidth: `30rem`,
  },

  desktopFullHD: {
    maxWidth: `47rem`,
  },
});

export const right: TRuleWithTheme = ({ theme }) => ({
  order: 1,
  tabletLarge: {
    order: 2,
  },
});

export const unicorn: TRuleWithTheme = ({ theme }) => ({
  height: `auto`,
  maxWidth: `100%`,
});
