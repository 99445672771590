import React from 'react';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';

import * as felaRules from './TeamCard.styles';
import AckeeIcon from '../AckeeIcon';
import ArrowRightIcon from '../ArrowRightIcon';
import CardLayout from './Layout';

export interface TeamFrontCardProps {
  name: string;
  surname: string;
  link: string;
  sinceYear: string | number;
  specialization: string;
  handleFlip?: Function;
  extend?: any;
  ImageComponent?: React.ReactNode;
  videoSrc?: any;
  mottoTitle: string;
}

const FrontCard: React.FC<TeamFrontCardProps> = ({
  name,
  surname,
  link,
  sinceYear,
  mottoTitle,
  specialization,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleFlip,
  extend,
  ImageComponent,
  videoSrc,
}) => {
  const { styles } = useFelaEnhanced(felaRules);

  return (
    <CardLayout name={name} surname={surname} link={link} extend={extend}>
      <div className={styles.picture}>
        <div className={styles.pictureCircle}>
          {videoSrc && (
            <video autoPlay muted loop className={styles.video}>
              <source src={videoSrc} type="video/mp4" />
              {ImageComponent ? ImageComponent : `ERR`}
            </video>
          )}
          {!videoSrc && ImageComponent}
        </div>
      </div>

      <div className={styles.bodyRow}>
        <AckeeIcon />
        &nbsp;since&nbsp;{sinceYear}
      </div>
      <div className={styles.bodyRow}>{specialization}</div>
      <div className={styles.bodyMottoRow}>
        <span>{mottoTitle}</span>
        {handleFlip && (
          <button
            type="button"
            className={styles.flipButton}
            onClick={() => handleFlip()}
          >
            <ArrowRightIcon />
          </button>
        )}
      </div>
    </CardLayout>
  );
};

export default FrontCard;
