import React from 'react';

import * as felaRules from './OpenSource.styles';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';
import Section from '../section';
import Paragraph from '../Paragraph';
import LargeTitle from '../LargeTitle';
import StackedButton from '../StackedButton';

export const OpenSource: React.FC = () => {
  const { styles, rules } = useFelaEnhanced(felaRules);

  return (
    <Section
      extend={{ wrapper: rules.extendSection, inner: rules.extendSectionInner }}
    >
      <LargeTitle htmlType="h2" extend={{ container: rules.extendTitle }}>
        Open Source
      </LargeTitle>

      <div className={styles.sectionWrapper}>
        <StackedButton
          RenderComponent="a"
          href="https://wtfisackee.dev/"
          target="_blank"
          extend={{ container: rules.buttonWrapper }}
        >
          Wtf is Ackee
        </StackedButton>

        <Paragraph extend={{ container: rules.extendParagraph }}>
          Browse through the signpost of our open source projects.
        </Paragraph>
      </div>

      <div className={styles.sectionWrapper}>
        <StackedButton
          RenderComponent="a"
          href="https://frontend-cookbook.ack.ee/"
          target="_blank"
          extend={{ container: rules.buttonWrapper }}
        >
          Cookbook
        </StackedButton>

        <Paragraph extend={{ container: rules.extendParagraph }}>
          Get inspired from our delicious recipes.
        </Paragraph>
      </div>

      <div className={styles.sectionWrapper}>
        <StackedButton
          RenderComponent="a"
          href="https://github.com/search?q=topic%3Afrontend+org%3AAckeeCZ+fork%3Atrue"
          target="_blank"
          rel="nofollow"
          extend={{ container: rules.buttonWrapper }}
        >
          GitHub
        </StackedButton>

        <Paragraph extend={{ container: rules.extendParagraph }}>
          Don't be afraid to try and taste our code.
        </Paragraph>
      </div>
    </Section>
  );
};

export default OpenSource;
