import React from 'react';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';

import * as felaRules from './OvalButton.styles';

interface OvalButtonProps {
  htmlType: string;
}

const OvalButton: React.FC<OvalButtonProps> = ({
  children,
  htmlType,
  ...props
}) => {
  const { styles } = useFelaEnhanced(felaRules);

  return React.createElement(htmlType, {
    ...props,
    children: <span className={styles.innerWrapper}>{children}</span>,
    className: styles.container,
  });
};

export default OvalButton;
