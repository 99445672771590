import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

export const wrapper: TRuleWithTheme<{ flipped: boolean }> = ({
  theme,
  flipped,
}) => ({
  position: `relative`,
  display: `flex`,
  flexDirection: `column`,
  transformStyle: `preserve-3d`,
  transition: theme.transitionCard,
  transform: flipped ? `rotateY(180deg)` : `rotateY(0deg)`,
});

export const frontWrapper: TRuleWithTheme = ({ theme }) => ({
  position: `relative`,
  flex: 1,
  zIndex: 2,

  width: `100%`,
  height: `100%`,
  top: 0,
  left: 0,

  backfaceVisibility: `hidden`,
  '-webkit-backface-visibility': `hidden`,
  transition: theme.transitionCard,
});

export const backWrapper: TRuleWithTheme = () => ({
  backfaceVisibility: `hidden`,
  '-webkit-backface-visibility': `hidden`,

  flex: 1,
  flexGrow: 1,
  flexShrink: 0,

  position: `absolute`,
  top: 0,
  left: 0,

  width: `100%`,
  height: `100%`,

  transform: `rotateY(180deg)`,
});

export const backBody: TRuleWithTheme = ({ theme }) => ({
  display: `flex`,
});
