import React from 'react';

import Card from '../Card';

import * as felaRules from './ProjectCard.styles';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';

export interface ProjectCardProps {
  img?: string;
  link?: string;
  name?: string;
  ImageComponent: React.ReactNode;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  ImageComponent,
  name,
  link,
}) => {
  const { styles } = useFelaEnhanced(felaRules);

  return (
    <a href={link} className={styles.col} target="_blank" rel="noreferrer">
      <Card footerText={name}>{ImageComponent}</Card>
    </a>
  );
};

export default ProjectCard;
