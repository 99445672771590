import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

export default function Seo(): JSX.Element {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            siteUrl
          }
        }
      }
    `,
  );

  return (
    <Helmet
      htmlAttributes={{
        lang: `en`,
      }}
      title={siteMetadata.title}
      meta={[
        {
          property: `og:title`,
          content: siteMetadata.title,
        },
        {
          name: `description`,
          content: siteMetadata.description,
        },
        {
          property: `og:description`,
          content: siteMetadata.description,
        },
        {
          property: `og:image`,
          content: `${siteMetadata.siteUrl}${siteMetadata.image}`,
        },
        {
          property: `og:image:alt`,
          content: `ReactBoys.com`,
        },
        {
          property: `og:url`,
          content: `${siteMetadata.siteUrl}/`,
        },
        {
          property: `og:image:type`,
          content: `image/png`,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `630`,
        },
        {
          property: `og:locale`,
          content: `en`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: siteMetadata.title,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: siteMetadata.description,
        },
      ]}
    />
  );
}
