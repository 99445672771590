import React, { FunctionComponent, ReactHTML } from 'react';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';

import * as felaRules from './StackedButton.styles';
import { RulesExtend } from '../../gatsby-theme-fela/styles/theme';

interface StackedButtonProps {
  RenderComponent?:
    | FunctionComponent<any>
    | keyof ReactHTML
    | React.ElementType;
  baseColor?: string;
  extend?: RulesExtend<typeof felaRules>;
  [key: string]: any;
}

const StackedButton: React.FC<StackedButtonProps> = ({
  children,
  RenderComponent = `button`,
  baseColor,
  extend,
  ...props
}) => {
  const { styles } = useFelaEnhanced(felaRules, { extend, baseColor });

  return React.createElement(RenderComponent, {
    ...props,
    children: <span className={styles.innerWrapper}>{children}</span>,
    className: styles.container,
  });
};

export default StackedButton;
