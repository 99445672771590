import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

export const extendSection: TRuleWithTheme = ({ theme }) => ({
  backgroundColor: theme.colors.blue,
  color: theme.colors.white,
  maxWidth: `88rem`,
  marginLeft: `auto`,
  marginRight: `auto`,
  width: `100%`,
});

export const extendTitle: TRuleWithTheme = ({ theme }) => ({
  color: theme.colors.white,
  fontSize: `2.5rem`,
  letterSpacing: `1.82px`,
  textShadow: `2px 2px ${theme.colors.black}`,
});

export const grid: TRuleWithTheme = ({ theme }) => ({
  marginTop: `3.75rem`,
  desktop: {
    display: `flex`,
    flexWrap: `wrap`,
  },
  // maxWidth: `53rem`,
  marginLeft: `auto`,
  marginRight: `auto`,
});

export const readMoreWrapper: TRuleWithTheme = ({ theme }) => ({
  marginTop: `3.25rem`,
  textAlign: `center`,
});
