import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

export const extendTitle: TRuleWithTheme = ({ theme }) => ({
  textShadow: `2px 2px ${theme.colors.blue}`,
  color: theme.colors.white,
  fontSize: `2.5rem`,
  letterSpacing: `1.82px`,
});

const colSpacing = 32;

export const grid: TRuleWithTheme = ({ theme }) => ({
  marginTop: `3.75rem`,
  display: `flex`,
  flexWrap: `wrap`,
  maxWidth: `60rem`,
  marginLeft: `auto`,
  marginRight: `auto`,
  justifyContent: `center`,
});

export const col: TRuleWithTheme = ({ theme }) => ({
  margin: colSpacing,
  textDecoration: `none`,

  desktop: {
    flex: `0 0 calc(16.666% - ${colSpacing * 2}px)`,
  },
});
