import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

const colSpacing = 16;

export const col: TRuleWithTheme = ({ theme }) => ({
  margin: colSpacing,
  display: `block`,
  textDecoration: `none`,

  desktop: {
    flex: `1 0 calc(50% - ${colSpacing * 2}px)`,

    ':first-child': {
      flex: `1 1 100%`,
    },
  },
});
