import React from 'react';

import * as felaRules from './Section.styles';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';
import { RulesExtend } from '../../gatsby-theme-fela/styles/theme';

export interface SectionProps {
  extend?: RulesExtend<typeof felaRules>;
  id?: string;
}

const Section: React.FC<SectionProps> = ({ children, extend, id }) => {
  const { styles } = useFelaEnhanced(felaRules, { extend });

  return (
    <div className={styles.wrapper} id={id}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

export default Section;
