import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

export const header: TRuleWithTheme = ({ theme }) => ({
  backgroundColor: theme.colors.blue,
  height: `100%`,
  paddingBottom: `3rem`,
  display: `flex`,
  alignItems: `flex-start`,
  flexDirection: `column`,

  tabletLarge: {
    height: `100vh`,
    minHeight: 800,
    justifyContent: `space-between`,
    paddingBottom: `6rem`,
  },
});

export const topWrapper: TRuleWithTheme = ({ theme }) => ({
  display: `flex`,
  alignItems: `flex-start`,
  justifyContent: `space-between`,

  padding: `1.5rem`,
  tabletLarge: {
    padding: `3.75rem 5rem 2.5rem 3.75rem`,
  },

  width: `100%`,
});

export const joinUs: TRuleWithTheme = ({ theme }) => ({
  border: `none`,
  textTransform: `uppercase`,
  backgroundColor: theme.colors.white,
  color: theme.colors.blue,
  padding: `8px 12px`,
  display: `block`,
  textDecoration: `none`,
});

export const titleWrapper: TRuleWithTheme = ({ theme }) => ({
  textAlign: `center`,
  overflow: `visible`,
  marginLeft: `auto`,
  marginRight: `auto`,
  marginBottom: `2rem`,
});

export const contentWrapper: TRuleWithTheme = ({ theme }) => ({
  width: `100%`,
  maxWidth: `52rem`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  marginTop: `3.75rem`,
  marginLeft: `auto`,
  marginRight: `auto`,
});

export const contentTextWrapper: TRuleWithTheme = ({ theme }) => ({
  padding: `0 1.5rem`,

  tabletLarge: {
    padding: `0 6rem`,
  },
});

export const readMore: TRuleWithTheme = ({ theme }) => ({
  marginTop: `2rem`,

  marginLeft: `auto`,
  marginRight: `auto`,

  tabletLarge: {
    marginRight: `unset`,
    marginLeft: `auto`,
    marginBottom: `2rem`,
  },
  color: theme.colors.white,
  display: `block`,
  border: `none`,
  textDecoration: `none`,
});

export const extendTitle: TRuleWithTheme = ({ theme }) => ({
  paddingLeft: `10rem`,
  paddingRight: `10rem`,
});

export const extendParagraph: TRuleWithTheme = ({ theme }) => ({
  '@media(max-height: 880px)': {
    fontSize: `1rem`,
    lineHeight: `1.55rem`,
  },
});
