import React from 'react';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';

import * as felaRules from './LargeTitle.styles';

const LargeTitle: React.FC<{
  htmlType?: string;
  extend?: any;
  responsive?: boolean;
}> = ({ children, htmlType = `h1`, responsive = false, extend }) => {
  const { styles } = useFelaEnhanced(felaRules, { extend, responsive });

  return React.createElement(htmlType, {
    className: styles.container,
    children,
  });
};

export default LargeTitle;
