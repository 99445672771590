import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

export const wrapper: TRuleWithTheme = ({ theme }) => ({
  backgroundColor: theme.colors.black,
  padding: theme.sizing.sectionPaddingSmall,
  tablet: {
    padding: theme.sizing.sectionPadding,
  },
  width: `100%`,
});

export const inner: TRuleWithTheme = ({ theme }) => ({
  maxWidth: theme.sizing.maxSectionWidth,
  marginLeft: `auto`,
  marginRight: `auto`,
});
