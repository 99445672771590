import React from 'react';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';

import * as felaRules from './TeamCard.styles';
import ArrowLeftIcon from '../ArrowLeftIcon';
import CardLayout from './Layout';
import Paragraph from '../Paragraph';

export interface TeamBackCardProps {
  name: string;
  surname: string;
  link: string;
  motto: string;
  handleFlip: Function;

  extend?: any;
}

const BackCard: React.FC<TeamBackCardProps> = ({
  name,
  surname,
  link,
  motto,
  handleFlip,
  extend,
}) => {
  const ref = React.useRef<HTMLDivElement>();
  const { styles, rules } = useFelaEnhanced(felaRules, {
    isBack: true,
  });

  // FIXME: Ugly hack to force Safari to re-render element to calculate correct height
  React.useEffect(() => {
    let timeoutId;

    if (ref.current) {
      ref.current.style.height = `100.0001%`;

      timeoutId = setTimeout(() => {
        ref.current.style.height = `100%`;
      }, 100);
    }

    return () => timeoutId && clearTimeout(timeoutId);
  }, [extend]);

  return (
    <CardLayout name={name} surname={surname} link={link} extend={extend}>
      <div className={styles.backBody} ref={ref}>
        <Paragraph extend={{ container: rules.paragraphExtend }} allowHtml>
          {motto}
        </Paragraph>

        <div className={styles.bodyMottoRow}>
          <span>&nbsp;</span>
          <button
            type="button"
            className={styles.flipButton}
            onClick={() => handleFlip()}
          >
            <ArrowLeftIcon />
          </button>
        </div>
      </div>
    </CardLayout>
  );
};

export default BackCard;
