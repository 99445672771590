import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { ProjectCardProps } from '../ProjectCard/ProjectCard';

const projectsData: ProjectCardProps[] = [
  {
    ImageComponent: (
      <StaticImage
        src="../../images/projects/flashsport.svg"
        alt="FlashSport App"
        placeholder={`tracedSVG`}
        quality={100}
      />
    ),
    link: `https://www.ackee.cz/en/references/flashsport`,
    name: `Sport News at the Speed of Light`,
  },
  {
    ImageComponent: (
      <StaticImage
        src="../../images/projects/simplifa.svg"
        alt="Simplifa"
        width={260}
        placeholder={`tracedSVG`}
        quality={100}
      />
    ),
    link: `https://www.ackee.cz/en/simplifa`,
    name: `Digital Management of Elevator Systems`,
  },
  {
    ImageComponent: (
      <StaticImage
        src="../../images/projects/hi5.svg"
        alt="Hi5"
        placeholder={`tracedSVG`}
        quality={100}
      />
    ),
    link: `https://www.ackee.cz/en/hi5`,
    name: `Millions of Prints a Year`,
  },
];

export default projectsData;
