import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={46}
      height={54}
      viewBox="0 0 46 54"
      {...props}
    >
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        filter="url(#prefix__a)"
        transform="translate(-7 -3)"
      >
        <g fill="#000">
          <path d="M19.164 18.375l-.077-1.125h21.666l-.076 1.125-.23 2.175-.077.9H23.834l.383 4.35H39.988l-.153 1.125-.996 11.176-.076.75-8.804 2.379v.02h-.077l-8.881-2.399-.536-6.676h4.287l.306 3.375 4.824 1.276 4.823-1.276.537-5.475H20.236z" />
          <path d="M7.5 3.75l4.371 47.027L30 56.25l18.128-5.473L52.5 3.75h-45zm6.472 45.311L9.969 6.001h40.062l-4.003 43.06L30 53.9 13.972 49.06z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
