import React from 'react';

export const ArrowLeftIcon: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="26"
      viewBox="0 0 15 26"
      {...props}
    >
      <polyline
        fill="none"
        stroke="#00F"
        strokeWidth="2"
        points="48 19 36 31 24 19"
        transform="matrix(0 -1 -1 0 33 49)"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
