import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={54}
      height={54}
      viewBox="0 0 54 54"
      {...props}
    >
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        fill="#000"
        fillRule="evenodd"
        filter="url(#prefix__a)"
        transform="translate(-3 -3)"
      >
        <path d="M3 57h54V3H3v54zm2.25-2.25h49.5V5.25H5.25v49.5z" />
        <path d="M41.95 51.318c.958.173 3.074.198 3.966.041 2.885-.489 4.934-1.96 5.761-4.125.751-1.993.502-4.67-.604-6.348-.99-1.505-2.585-2.563-6.313-4.165-2.025-.878-2.67-1.307-3.017-2.026-.166-.332-.223-.572-.223-.977-.017-1.38 1.008-2.206 2.587-2.099 1.075.074 1.777.495 2.447 1.47.223.332.423.547.471.514 1.373-.852 3.637-2.373 3.621-2.438-.057-.223-.768-1.158-1.248-1.67-.927-.958-1.935-1.546-3.257-1.876v.003c-.9-.223-3.034-.288-3.952-.115-2.827.537-4.786 2.338-5.34 4.942-.156.744-.106 2.587.092 3.322.24.885.818 1.943 1.43 2.604 1.058 1.14 2.215 1.86 4.901 3.017 2.349 1.017 3.175 1.505 3.58 2.083.315.454.389.719.389 1.322 0 .67-.198 1.125-.679 1.563-1.124 1.007-3.405 1.131-5.066.255-.579-.306-1.537-1.222-1.975-1.892l-.314-.464-1.497.868-1.934 1.115c-.24.14-.456.274-.48.307-.058.099.875 1.48 1.355 2.008 1.224 1.356 3.207 2.389 5.298 2.76m-19.587-.137h4.794V31.922h6.777l-.016-2.131-.025-2.123-9.1-.025c-5.001-.008-9.126.008-9.15.041l-.057 2.147v2.091h6.777V51.18z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
