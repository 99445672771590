import React from 'react';

import * as felaRules from './Blogs.styles';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';
import Section from '../section';
import LargeTitle from '../LargeTitle';

import blogsData from './blogsData';
import Card from '../Card';

import StackedButton from '../StackedButton';

export const Blogs: React.FC = () => {
  const { styles, rules } = useFelaEnhanced(felaRules);

  return (
    <Section extend={{ wrapper: rules.extendSection }}>
      <LargeTitle htmlType="h2" extend={{ container: rules.extendTitle }}>
        Blogs &amp; Talks
      </LargeTitle>

      <div className={styles.grid}>
        {blogsData.map(({ ImageComponent, ...item }, index) => (
          <a
            className={styles.col}
            href={item.link}
            target="_blank"
            key={index}
            rel="noreferrer"
          >
            <Card
              footerText={item.name}
              extend={{
                body: rules.extendCardBody,
                wrapper: rules.extendCardWrapper,
              }}
            >
              {ImageComponent}
            </Card>
          </a>
        ))}
      </div>

      <div className={styles.readMoreWrapper}>
        <StackedButton
          RenderComponent="a"
          href="https://www.ackee.cz/blog/en/category/web/"
          target="_blank"
          baseColor={`black`}
        >
          Load more
        </StackedButton>
      </div>
    </Section>
  );
};

export default Blogs;
